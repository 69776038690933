import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import {
  getSubCRMUsers,
} from '../../../redux/crmUser/crmUserActions';
import { addWhitelistIP, updateState } from '../../../redux/security/whitelistIPActions';
import { ModalContext } from '../../../context';

export function AddWhitelistIPModal() {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const agentUsers = useSelector((state) => state?.users?.allAgents);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);

  const error = useSelector((state) => state?.whitelistIP?.error);

  const {
    register, handleSubmit, control, formState: { errors },
  } = useForm();

  useEffect(() => {
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const id = data?.roleId;
    const uid = data?._id;

    dispatch(getSubCRMUsers(id, uid));
  }, []);

  const whitelistIPData = {
    name: {
      required: 'Name is required',
    },
    ipAddress: {
      required: 'IP Address is required',
      pattern: {
        value: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        message: 'Please enter a valid IP Address',
      },
    },
  };

  const handleSave = (formData) => {
    const data = {
      name: formData.name,
      ip: formData.ipAddress,
    };

    if (formData.manager) data.managerId = formData.manager;

    dispatch(addWhitelistIP(data));
    hideModal();
  };

  useEffect(() => {
    if (error) {
      dispatch(updateState());
    }
  }, [error]);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="form-group col-md-12">
        <label className="control-label">Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Name"
          {...register('name', whitelistIPData.name)}
          name="name"
          defaultValue=""
          control={control}
        />
        {errors?.name && <span className="errMsg">{errors.name.message}</span>}
      </div>

      <div className="form-group col-md-12 pt-2">
        <label className="control-label">IP Address</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter IP Address"
          {...register('ipAddress', whitelistIPData.ipAddress)}
          name="ipAddress"
          defaultValue=""
          control={control}
        />
        {errors?.ipAddress && <span className="errMsg">{errors.ipAddress.message}</span>}
      </div>

      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Manager</label>
        <Form.Select name="manager" {...register('manager')}>
          <option value="">All</option>
          {crmUsers && crmUsers.length > 0 && crmUsers.map(((agentUser) => (
            <option value={agentUser._id} key={agentUser._id}>{`${agentUser?.firstName} ${agentUser?.lastName}`}</option>
          )))}
        </Form.Select>
        {errors?.manager && <span className="errMsg">{errors.manager.message}</span>}
      </div>

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">Save</button>
      </div>
    </form>
  );
}
